import React, { memo, useCallback } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Navbar, Footer, SEO } from "../../components";
import { packageTitlesAndIds } from "../../data/selectors";

const Portfolio = () => {
  const images = useStaticQuery(graphql`
    query indexPortfolioQuery {
      allFile(
        filter: { sourceInstanceName: { eq: "gallery" } }
        sort: { fields: childImageSharp___fixed___originalName, order: ASC }
      ) {
        nodes {
          childImageSharp {
            fixed(width: 480) {
              base64
              aspectRatio
              originalName
              src
              srcSet
            }
          }
          relativeDirectory
        }
      }
    }
  `).allFile.nodes;

  const getImage = useCallback(
    (id) => {
      const item = images.find((item) => item.relativeDirectory === id);
      return item ? (
        <Img
          fixed={item.childImageSharp.fixed}
          alt={id}
          className="rounded-lg w-full h-full"
          imgStyle={{
            objectPosition: `50% 20%`,
          }}
        />
      ) : null;
    },
    [images]
  );

  return (
    <div>
      <SEO
        title="Merkl Kinga - Munkáim, portfólió"
        description={`Merkl Kinga - Fotózás Gödöllőn és környékén: ${packageTitlesAndIds
          .map((item) => item.title)
          .join(", ")}`}
      />
      <Navbar />

      <div className="min-h-720 bg-0-500 grid grid-flow-row grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 px-16 py-24">
        {packageTitlesAndIds.map((item, index) => (
          <Link key={index} to={item.id}>
            <div className="bg-white rounded-lg shadow-md hover:shadow-lg h-full w-full relative min-h-256">
              <div className="bg-white w-full absolute bottom-0 left-0 z-10 text-center px-4 py-2 rounded-b-lg">
                {item.title}
              </div>
              {getImage(item.id)}
            </div>
          </Link>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default memo(Portfolio);
